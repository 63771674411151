<template>
  <div class="invoice-summary-component" v-if="!notEnabledServices">
    <div class="summary" >

      <div class="container">

        <div class="summary-container">
          <div class="alert" v-if="hasError && errorMessage" style="margin-top: -25px">
          {{ errorMessage }}
        </div>
          <div class="row">
            <div class="col-xs-12">
            <h2 class="title">
              {{ $t('services.summary.title') }}
            </h2>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6 col-md-3 col-lg-3">
            <div class="group-info">
              <span class="summary-info-title">{{ $t('services.summary.quotation') }}*</span>
              <span class="summary-info-value">R$ {{ quotation | currency }}</span>
            </div>
          </div>
          <div class="col-xs-6 col-md-3 col-lg-2">
            <div class="group-info">
              <span class="summary-info-title">{{ $t('services.summary.services') }}</span>
              <span class="summary-info-value">{{ getInvoiceItemsCount }}</span>
            </div>
          </div>
          <div class="col-xs-6 col-md-3 col-lg-2">
            <div class="group-info">
              <span class="summary-info-title">{{ $t('services.summary.fee') }}</span>
              <span class="summary-info-value">R$ {{ getInvoiceFee | currency }}</span>
            </div>
          </div>
          <div class="col-xs-6 col-md-3 col-lg-2">
            <div class="group-info">
              <span class="summary-info-title">{{ $t('services.summary.total') }}</span>
              <span class="summary-info-value">R$ {{ getInvoiceTotal | currency }}</span>
            </div>
          </div>
          <div class="col-xs-12 col-lg-3">
            <div class="group-info">
              <span class="summary-info-title contrast">{{ $t('services.summary.totalBtc') }}</span>
              <span class="summary-info-value contrast">BTC {{ BTCAmount | cryptocurrency }}</span>
            </div>
          </div>
        </div>
        </div>
        <div class="row network-select">
          <div class="col-xs-12 network-options">
            <div class="summary-info-title network-title">REDE</div>
            <input id="btc-network" type="radio" :checked="invoice.network === 'BTC'" @change.prevent="changeNetwork('BTC')" value="BTC"><label for="btc-network">Bitcoin</label>
            <input id="bep20-network" type="radio" :checked="invoice.network === 'BEP20'" @change.prevent="changeNetwork('BEP20')" value="BEP20" v-if="isEnabledBep20"><label for="bep20-network" v-if="isEnabledBep20">BEP20</label>
          </div>
          <div class="col-xs-12 alert" v-if="invoice.network === 'BEP20'">
            Você optou por pagar usando o protocolo BEP20, da rede Binance Smart Chain. <br />
            Se sua carteira NÃO for desta rede, não envie os Bitcoins pois suas moedas serão perdidas. Caso sua carteira não seja BEP20 escolha a rede bitcoin para finalizar seu pedido.
          </div>
        </div>
      </div>
    </div>

    <ct-invoice-checkout @finishInvoice="finishInvoice" />
    <ct-confirm-bsc-modal v-if="showModalConfirmation" @close="toogleConfirmationModal" @confirm="confirmBep20"/>

    <div class="container">
      <p class="quotation-obs">*{{ $t('services.summary.quotationObs') }}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CtInvoiceCheckout from './InvoiceCheckout';
import CtConfirmBscModal from './ConfirmBscModal';
import currency from '@/filters/currency';
import cryptocurrency from '@/filters/cryptocurrency';

export default {
  name: 'InvoiceSummary',
  components: {
    CtInvoiceCheckout,
    CtConfirmBscModal,
  },
  filters: {
    currency,
    cryptocurrency,
  },
  data () {
    return {
      showModalConfirmation: false,
    };
  },
  props: {

    tickerData: {
      type: Object,
      required: true,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapState({
      invoice: (state) => state.services.invoice,
      settings: (state) => state.settings.settings,
    }),
    ...mapGetters(['getInvoiceItemsCount', 'getInvoiceTotal', 'getInvoiceFee', 'notEnabledServices']),
    quotation () {
      if (!this.tickerData || !this.tickerData.BTCBRL || !this.tickerData.BTCBRL.buy) {
        return 0;
      }

      return this.tickerData.BTCBRL.buy;
    },
    isEnabledBep20 () {
      if (!this.settings?.networks?.length) return false;
      return this.settings.networks.includes('BEP20');
    },
    BTCAmount () {
      if (this.quotation === 0) {
        return 0;
      }

      return this.getInvoiceTotal / this.quotation;
    },
  },
  methods: {
    emitFinishEvent (event, resolve) {
      this.$emit('finishInvoice', event, resolve);
    },

    finishInvoice (event, resolve) {
      if (this.confirmed || this.invoice.network !== 'BEP20') {
        return this.emitFinishEvent(event, resolve);
      }
      this.toogleConfirmationModal();
    },

    confirmBep20 () {
      this.confirmed = true;
      this.toogleConfirmationModal();
      this.finishInvoice();
    },

    toogleConfirmationModal () {
      this.showModalConfirmation = !this.showModalConfirmation;
    },

    async changeNetwork (network) {
      this.confirmed = false;
      this.$emit('change-network', network);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../themes/variables";

.invoice-summary-component{
  margin-bottom: 60px;

  .summary {
    background-color: $background-gray;
  }

  .summary-container {
    padding: 25px 0;
    border-top: 2px solid $spotlight-color;

    @media only screen and (max-width: $grid-xs-max) {
      padding: 25px 20px;
    }
  }

  .network-select {
    padding-bottom: 10px;

    @media only screen and (max-width: $grid-lg-max) {
      padding: 0 20px;
    }
    .network-title {
      color: #414042;
      text-transform: uppercase;
      font-size: 1.5em;
      font-weight: 200;
      margin: 0 0 15px 0;
      text-align: left;
    }

    .network-options {

      display: inline-block;
      padding-bottom: 10px;
      /* text-align: center; */
    }
    label {
      display: inline;
      font-size: 1.6em;
      margin-right: 20px;

    }
    input[type='radio'] {
      width: 17px;
      height: 17px;
      margin-right: 4px;
      display: inline-block;
    }

    .alert {
      padding: 10px;
      margin: 5px 0;
      height: auto;
      line-height: 1.5em;
      @media only screen and (max-width: $grid-lg-max) {
        text-align: left;
      }
    }
  }

  .title {
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: left;
  }

  .group-info {
    span{
      color: $charcoal-grey-color;
      display: block;
      margin: 20px 0;

      &.summary-info-title{
        text-transform: uppercase;
        font-size: 1.5em;
        font-weight: 200;
      }

      &.summary-info-value{
        font-family: Oswald, sans-serif;
        font-size: 1.5em;
        font-weight: 600;
        margin-top: 15px;
        color: $charcoal-grey-color;
        @media only screen and (max-width: $grid-md-max) {
          font-size: 1.3em;
          margin-top: 8px;
        }
      }

      &.contrast{
        font-size: 1.9em;
      @media only screen and (max-width: $grid-md-max) {
        font-size: 1.7em;
      }
      }
    }
    p {
      font-family: Oswald, sans-serif;
      font-size: 1.5em;
      font-weight: 600;
      margin-top: 15px;
      color: $charcoal-grey-color;
      @media only screen and (max-width: $grid-md-max) {
        font-size: 16px;
        margin-top: 8px;
      }
    }
    p.big {
      font-size: 30px;
      @media only screen and (max-width: $grid-md-max) {
        margin-top: 15px;
      }
    }
  }

  .quotation-obs {
    padding: 10px 0px;
  }
}
</style>
